import { createApp }    from 'vue'
import App              from './App.vue'
import router           from './router'
import vue3GoogleLogin  from 'vue3-google-login'
import store            from './store.js';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './main.css';



const app = createApp(App)
app.use(router)
app.use(store);

app.use(vue3GoogleLogin, {
    clientId: '523873639761-72s9938hj8sdsj45okpjgc0arfrd95k9.apps.googleusercontent.com'
  })
app.mount('#app')