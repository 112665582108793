import { createStore } from 'vuex';

const store = createStore({
  state: {
    form: {
        name: '',
        email: '',
        password: '',
        phone_number: '',
        logged_with: 'default',
        phone_code: '+34',
        business_type: 'empresa',
        business_name: '',
        business_real_name: '',
        num_employees: '1',
        country_code: 'ES',
        want_school: null,
        business_cif: '',
        want_implantacion: false,
        want_software: false,
        want_trasspasso: false,
        stripe_credit_card_token: false,
        text_help: '',
    }
  },
  mutations: {
    increment(state) {
      state.count++;
    },
    decrement(state) {
      state.count--;
    }
  },
  actions: {
    increment({ commit }) {
      commit('increment');
    },
    decrement({ commit }) {
      commit('decrement');
    }
  },
  getters: {
    doubleCount: state => state.count * 2
  }
});

export default store;
